import Swal from 'sweetalert2'

export const camelize = str => str.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => (index === 0 ? word.toLowerCase() : word.toUpperCase()))
  .replace(/\s+/g, '')

export const capitalize = (str, space = ' ') => str.replace(/(?:^\w|[A-Z]|\b\w)/g, word => word.toUpperCase())
  .replace(/[-_\s+]/g, space)

export const confirm = (message, title = 'Apa anda yakin?') => new Promise(((resolve, reject) => {
  Swal.fire({
    title,
    text: message,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Ya',
    cancelButtonText: 'Tidak',
    reverseButtons: true,
  }).then(result => {
    if (result.value) {
      resolve(result)
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      reject(result)
    }
  })
}))

export const groupBy = function(xs, key) {
  return xs.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const currencyFormat = (nominal, withCurrency = true) => {
  const config = { maximumSignificantDigits: 3 }

  if (withCurrency) {
    config.style = 'currency'
    config.currency = 'IDR'
  }

  return new Intl.NumberFormat('id-ID', config).format(nominal)
}

export const isPromise = p => {
  if (
    p !== null &&
    (typeof p === 'object' || typeof p === 'function') &&
    typeof p.then === 'function' &&
    typeof p.catch === 'function'
  ) {
    return true;
  }

  return false;
}